import { useState } from 'react'

const toggleAccordion = () => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false)

  const handleToggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen)
  }

  return {
    handleToggleAccordion,
    isAccordionOpen,
  }
}

export default toggleAccordion;