import Header from 'components/Header'
import FooterNew from 'components/FooterNew'

import SuccessRegistration from './components/SuccessRegistration'


const FormSuccess = () => {

  return (
    <div>
      <Header />
      <SuccessRegistration />
      <FooterNew />
    </div>
  )
}


export default FormSuccess