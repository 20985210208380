import { useEffect, useState } from 'react'
import isClient from 'utils/isClient'
import Routes from 'Routes'

const useFooter = () => {
  const { aboutOnze } = Routes
  const [isOnzeManager, setIsOnzeManager] = useState(false)
  
  useEffect(() => {
    if(isClient()) {
      const currentPath = window.location.pathname
      setIsOnzeManager(currentPath === aboutOnze.path)
    }
  }, [])

  return {
    isOnzeManager
  }
}

export default useFooter