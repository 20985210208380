import Icon from 'rv-components-onze/src/Icon'
import Condition from 'components/Condition'
import enhancer from './hooks'
import classNames from 'classnames'

const Accordion = ({
  title,
  handleToggleAccordion,
  isAccordionOpen,
  phrasesList = [],
  showPressContact = false,
}) => {
  const accordionHeaderIcon = classNames('accordion__icon', {
    'accordion__icon--down': isAccordionOpen,
  })
  return (
    <div className="accordion" onClick={handleToggleAccordion}>
      <div className="accordion__header">
        <p className="accordion__title">{title}</p>
        <Icon iconId="angle-down" customClass={accordionHeaderIcon} />
      </div>
      <Condition condition={isAccordionOpen}>
        <div className="accordion__text-container">
          {phrasesList.map((phrase, idx) => (
            <a key={idx} href={phrase.link} className="accordion__text">
              {' '}
              <p>{phrase.text}</p>
            </a>
          ))}
          <Condition condition={showPressContact}>
            <h4 className="footer-landing-main-info__description press-contact">
              <b>Imprensa</b>
            </h4>
            <p className="footer-landing-main-info__description press-contact__description">
              rcovre@onze.com.br
            </p>
            <p className="footer-landing-main-info__description press-contact__description">
              +55 (11) 2344-9100
            </p>
          </Condition>
        </div>
      </Condition>
    </div>
  )
}

export default enhancer(Accordion)
