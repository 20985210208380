import Button from 'rv-components-onze/src/Button'
import Typography from 'rv-components-onze/src/Typography'

import Img from 'components/Img'

const Card = ({ image, children, title, link }) => {
  return (
    <a className="card" href={link} target="_blank" title={title}>
      <Img
        className="card__image"
        src={`${image}`}
        alt="imagem"
      />
      <div className="card__content">
        <Typography as="h4" variant="subtitle" weight="normal" className="card__title">
          {title}
        </Typography>
        <Typography as="p" variant="body1" className="card__text">
          {children}
        </Typography>
        <Button
          label="Acesse agora"
          iconId="right-arrow"
          iconPosition="right"
          buttonStyle="text"
          customClass="card__button"
        />
      </div>
    </a>
  );
}

export default Card