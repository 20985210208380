import R from 'ramda'
import Typography from 'rv-components-onze/src/Typography'
import { useLocation } from '@reach/router'
import queryString from 'query-string'

import Card from '../Card'

const cardsList = [
  {
    image: '/img-prev-privada-corporativa.png',
    title: 'Por que oferecer um plano de Previdência?',
    description: 'Confira 8 razões para fechar um plano de previdência corporativa na sua empresa.',
    link: 'https://www.onze.com.br/blog/previdencia-privada-corporativa/'
  },
  {
    image: '/img-stress-financeiro.png',
    title: 'Estresse Financeiro: tenha funcionários mais produtivos',
    description: 'Saiba como evitar que as preocupações com dinheiro abalem seus funcionários e derrubem a produtividade da empresa.',
    link: 'https://www.onze.com.br/blog/estresse-financeiro-funcionarios/'
  },
  {
    image: '/img-blog-onze.png',
    title: 'Mais de 1000 artigos publicados por especialistas',
    description: 'Um canal de conteúdo sobre previdência, benefícios, tendências em RH e muito mais.',
    link: 'https://www.onze.com.br/blog/'
  }
]

const messageB2B = {
  text: "Em breve nossos especialistas entrarão em contato com você!",
  title: "Obrigado!"
}
const messageB2C = {
  text: `Por enquanto nossa solução se destina à empresas e seus colaboradores. 
  Você receberá comunicação exclusiva para fazer parte dos primeiros clientes com benefícios únicos.`,
  title: "Obrigado pelo contato!"
}


const SuccessRegistration = () => {
  const location = useLocation()
  const queryStrings = queryString.parse(location.search)
  const currentMessageType = R.propOr('b2b', 'message', queryStrings)

  return (
    <section className="success">
      <div className="success__content">
        <div className="success__header">
          <Typography
            as="h1"
            variant="headline4"
            className="success__header-title"
          >
            {currentMessageType === 'b2c' ? messageB2C.title : messageB2B.title}
          </Typography>
          <Typography
            as="h2"
            variant="subtitle"
            className="success__header-description"
            weight="normal"
          >
            {currentMessageType === 'b2c' ? messageB2C.text : messageB2B.text}
            
          </Typography>
        </div>

        <div className="success__article">
          <div className="container success__recommendation">
            <Typography
              as="h3"
              variant="headline5"
              className="success__recommendation-text"
            >
              Alguns materiais que
              recomendamos para você:
            </Typography>
          </div>

          <div className="container success__cards">
            {
              cardsList.map(({ description, ...props }, index) => (
                <Card {...props} key={`card-${index}`} >
                  {description}
                </Card>
              ))
            }
          </div>
        </div>
      </div>
    </section>
  );
}

export default SuccessRegistration
